import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import router from './router';
import i18n from './i18n';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts';

Vue.use(VueApexCharts);
Vue.use(VueSweetalert2);
// Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
