import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    reporteHorasLaboradasFecha: {},
    verHorasLaboradasPorSemanaDialog: false,
    botonPersonalSeleccionado: 'activo',
    cambiarClaveDialog: false,
    cesePersonalDialog: false,
    cliente: {},
    conceptosSunat: [],
    direccionClienteAEditar: {},
    directionsRenderer: {},
    directionsService: {},
    drawerLanding: false,
    editPerson: false,
    editServiceDialog: false,
    editarAsistenciaDialog: false,
    editarDescansoMedicoDialog: false,
    editarDireccionClienteDialog: false,
    editarLicenciaDialog: false,
    editarPrestamoDialog: false,
    editarSancionesDialog: false,
    editarVacacionesDialog: false,
    fechaAsistencia: {},
    formularioContactoSoporte: false,
    googleMap: {},
    mapaActual: {},
    marcadoresEnMapa: [],
    nuevaPlanillaCreada: {},
    nuevoRegistro: true,
    personalActivo: [],
    personalCesado: [],
    personalCompleto: [],
    personalParaProceso: [],
    planSeleccionado: {},
    planillas: [],
    planillaAdelantoDelPeriodo: [],
    planillasRemuneracionDelAnioDelPeriodo: [],
    planillasVacacionesDelAnioDelPeriodo: [],
    planillaSeleccionada: {},
    planillasNoviembreAnioPasado: [],
    planillasDiciembreAnioPasado: [],
    plansDialog: false,
    planVigenteActual: {},
    procesoPlanilla: {
      pasoDeProcesoActual: 1,
      fechaInicio: '',
      fechaFin: '',
      tipoProceso: '',
    },
    programacionDeRutas: {},
    renderCreateRoutes: false,
    renderEditRoutes: false,
    rolesDelSistema: {
      ACCESO_APP: ['Acceso app'],
      GERENTE: ['Gerente'],
      GESTOR_CREDITOS: ['Gestor de créditos'],
      GESTOR_OPERACIONES: ['Gestor de operaciones'],
      GESTOR_PERSONAL: ['Gestor del personal'],
    },
    selectorPersonas: false,
    selectorPlanillasDialog: false,
    serviceToEdit: {},
    showKickOffPlans: false,
    tablaActivaGestionPersonal: '',
    temporizadorMostrarGPSDeRuta: [],
    tipoReportePlanilla: '',
    usuarioLogueado: {},
    verNuevoPersonal: false,
    verPersonalActivo: true,
    verPersonalHistorico: false,
  },
  mutations: {
    //do something
  },
  actions: {
    //do something
  },
  modules: {},
});
