import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '*',
  //   name: 'Landing',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../components/landing/Index.vue'),
  // },
  {
    path: '*',
    name: 'Landing',
    component: () => import('../components/landingPage/Index.vue'),
  },
  {
    path: '/new-user',
    name: 'Register',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/auth/register/Index.vue'
      ),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/auth/login/Index.vue'
      ),
  },
  {
    path: '/dashboard',
    name: 'AdminView',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/dashboard/AdminView.vue'
      ),
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../components/modules/services/Index.vue'),
  },
  {
    path: '/persons',
    name: 'Personas',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/personnel/Index.vue'
      ),
  },
  {
    path: '/route',
    name: 'Routes',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/routes/Index.vue'
      ),
  },
  {
    path: '/flota',
    name: 'Flota',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/flota/Index.vue'
      ),
  },
  {
    path: '/report',
    name: 'Report',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/reports/Index.vue'
      ),
  },
  {
    path: '/incidence',
    name: 'Incidence',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/incidents/Index.vue'
      ),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/modules/configuration/Index.vue'
      ),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/landingPage/plans/Payment.vue'
      ),
  },
  {
    path: '/precios',
    name: 'Precios',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/landingPage/plans/PlansDialog.vue'
      ),
  },
  {
    path: '/eliminar-cuenta',
    name: 'EliminarCuenta',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../components/dashboard/FormularioEliminarCuenta.vue'
      ),
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
